import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/chatp/Projects/farming-season/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "gameplay"
    }}>{`Gameplay`}</h1>
    <hr></hr>
    <h3 {...{
      "id": "fox-attack"
    }}>{`Fox Attack`}</h3>
    <p>{`Sometimes the fox may invade your farm. You must chase it away. During invasion, you will not be able to plant or raise animals.`}</p>
    <p><img alt="mat" src={require("./public/images/fox-1.png")} /></p>
    <p>{`You can chase away the fox by winning the mini-game. If you lose, the fox will steal some products from your farm and the current quest reward will be deducted by 30%`}</p>
    <p><img alt="mat" src={require("./public/images/fox-2.png")} /></p>
    <p>{`If you don't want to play the mini-game, you can launch a firecracker to scare the fox away. Buyable from in-game shop.`}</p>
    <p><img alt="mat" src={require("./public/images/fox-3.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      